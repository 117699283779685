import { Button, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { TGetContractsItemValuation } from "../../../../../../api/contract/contract.types";
import {
  EMPLOYEE_STATUS,
  TGetEmployeeItem,
} from "../../../../../../api/employee/employee.types";
import EmployeeNotInZoho from "../../../../../../components/Employee/EmployeeNotInZoho";
import { cuideoTheme } from "../../../../../../containers/themes/defaultTheme";
import EditableAvatar from "../../../../../coordinadora/components/EditableAvatar";
import { TOnCandidateChangeFn } from "../../types/candidate.types";
import ZohoIcon from "../../../../../../assets/images/iconos/zoho.png";
import EmployeeStatusButton from "../../../../../../components/Candidato/EmployeeStatusButton";
import DescartarCandidatoButton from "../DiscardCandidateButton/DiscardCandidateButton";
import EmployeeStaffActions from "../../../../../../components/Candidato/EmployeeStaffActions";
import AssignCandidatoButton from "../../../../components/AssignCandidatoButton";
import {
  FavButton,
  TCreateFavoriteFn,
  TDeleteFavoriteFn,
  TUpdateFavoriteFn,
} from "../../../favorites";
import { useAuthUser } from "../../../../../../reducers/hooks/useAuthUser";
import { apiCreateUserFavorite } from "../../../../../../api/favorite/apiCreateUserFavoriteCandidate";
import { apiUpdateUserFavoriteCandidate } from "../../../../../../api/favorite/apiUpdateUserFavoriteCandidate";
import { apiDeleteUserFavoriteCandidate } from "../../../../../../api/favorite/apiDeleteUserFavoriteCandidate";
import { candidateFavoriteOfUser } from "../../util/candidate.util";
import CandidateFvStatus from "../CandidateFvStatus/CandidateFvStatus";

const StyledHeader = styled.div`
  background-color: ${cuideoTheme.palette.common.white};
`;

const StyledInfoRow = styled.div`
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAvatarWrapper = styled.div`
  margin: 0.5rem 1rem;
  .MuiAvatar-root {
    width: 5.625rem;
    height: 5.625rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
`;

const StyledDataWrapper = styled.div`
  flex-grow: 1;
`;

const StyledFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
`;

const StyledEmployeeName = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.15;
`;

const StyledSection = styled.div``;

const StyledEmployeeEstado = styled.div`
  .MuiCuideo-EmployeeEstado {
    display: block;
    line-height: 2.5rem;
    width: 6rem;
    font-size: 0.875rem;
    text-align: center;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;

  margin-right: 0.5rem;

  .MuiInputLabel-shrink {
    transform: translate(12px, 3px) scale(0.75);
  }

  .MuiFilledInput-root {
    height: 2.25rem;

    .MuiSelect-select {
      padding: 0.625rem 2rem 0 0.75rem;
      font-size: 1rem;
    }
  }
`;

const StyledZohoButton = styled(Button)`
  text-decoration: none;
  margin-left: 0.25rem;
  padding: 0.25rem;
`;

const StyledZohoButtonIcon = styled.img`
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.5rem;
`;

const StyledZohoButtonText = styled(Typography)`
  font-size: 0.75rem;
  text-transform: none;
`;

const StyledActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledEmployeeNameGroup = styled.div`
  display: block;
`;

const StyledEmployeeCode = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.875rem;
  line-height: 1.25;
  opacity: 0.5;
`;

const StyledEmployeeNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
`;

const StyledFavWrapper = styled.div``;

interface ICandidateHeaderProps {
  candidate: TGetEmployeeItem;
  valuation?: TGetContractsItemValuation;
  onChange: TOnCandidateChangeFn;
  onAvatarChange: any;
  currentContract?: any;
}

const CandidateHeader: React.FC<ICandidateHeaderProps> = (props) => {
  const { candidate, valuation, onChange, onAvatarChange, currentContract } = props;

  const { authUser } = useAuthUser();

  const handleAvatarChange = (
    avatarId: string | null,
    avatarUrl: string | null
  ) => {
    onAvatarChange?.();
  };

  const handleDiscard = () => {
    onChange("discard", candidate);
  };

  const handleCreateFavorite: TCreateFavoriteFn = async (
    candidateId: string,
    note?: string
  ) => {
    await apiCreateUserFavorite({
      user: authUser.userId,
      candidate: candidateId,
      note: note ?? null,
    });
    onChange("fav", { ...candidate });
  };

  const handleUpdateFavorite: TUpdateFavoriteFn = async (
    id: string,
    note?: string
  ) => {
    await apiUpdateUserFavoriteCandidate({
      id,
      note: note ?? null,
    });
    onChange("fav", { ...candidate });
  };

  const handleDeleteFavorite: TDeleteFavoriteFn = async (id: string) => {
    await apiDeleteUserFavoriteCandidate(id);
    onChange("fav", { ...candidate, favoritedBy: [] });
  };

  const handleCommunicationIntentCreated = () => {
    onChange("update", { ...candidate });
  };

  const canEditAvatar = !!!candidate.avatarUploadedByUser;
  const favorite = candidateFavoriteOfUser(candidate);

  return (
    <StyledHeader>
      <StyledInfoRow>
        <StyledAvatarWrapper>
          <EditableAvatar
            employeeId={candidate.id}
            fileUrl={candidate.avatarUrl ?? null}
            canEdit={canEditAvatar}
            onAvatarChanged={handleAvatarChange}
            isFelizVita={candidate.Company === "FV"}
          />
        </StyledAvatarWrapper>
        <StyledDataWrapper>
          <StyledFirstRow>
            <StyledEmployeeNameWrapper>
              <StyledEmployeeNameGroup>
                <StyledEmployeeName>
                  {candidate.Nombre} {candidate.Apellidos}
                </StyledEmployeeName>
                {candidate.Name ? (
                  <StyledEmployeeCode>{candidate.Name}</StyledEmployeeCode>
                ) : (
                  <EmployeeNotInZoho />
                )}
              </StyledEmployeeNameGroup>
              <StyledFavWrapper>
                <FavButton
                  candidateId={candidate.id}
                  favorite={favorite}
                  onCreateFavorite={handleCreateFavorite}
                  onDeleteFavorite={handleDeleteFavorite}
                  onUpdateFavorite={handleUpdateFavorite}
                />
              </StyledFavWrapper>
            </StyledEmployeeNameWrapper>
            <StyledZohoButton
              color="primary"
              onClick={() => {
                window.open(
                  `https://crm.zoho.com/crm/org638326163/tab/CustomModule15/${candidate.zohoId}`
                );
              }}
            >
              <StyledZohoButtonIcon src={ZohoIcon} />
              <StyledZohoButtonText>
                <FormattedMessage
                  id="CandidateHeader.Ver en Zoho"
                  defaultMessage="Ver en Zoho"
                />
              </StyledZohoButtonText>
            </StyledZohoButton>
          </StyledFirstRow>
          <StyledSecondRow>
            <StyledSection>
              <StyledEmployeeEstado>
                <EmployeeStatusButton
                  candidato={candidate}
                  disabled={
                    candidate.Estado_Cuideo === EMPLOYEE_STATUS.DISCARDED
                      ? true
                      : candidate.Estado_Cuideo ===
                        EMPLOYEE_STATUS.NOT_VALIDATED
                      ? candidate.Business_Country === "es" &&
                        authUser.userEmail !== "martacuesta@cuideo.com"
                      : true
                  }
                  allowedStatus={[
                    EMPLOYEE_STATUS.NOT_VALIDATED,
                    EMPLOYEE_STATUS.VALIDATED,
                  ]}
                />
                <DescartarCandidatoButton
                  candidate={candidate}
                  onDiscard={handleDiscard}
                />
                {!!candidate.Estado_FV && (
                  <CandidateFvStatus status={candidate.Estado_FV} />
                )}
              </StyledEmployeeEstado>
            </StyledSection>
            <StyledSection>
              <EmployeeStaffActions
                employee={candidate as any}
                valuation={valuation as any}
                onCandidateChange={handleCommunicationIntentCreated}
              />
            </StyledSection>
            <StyledSection>
              {!!valuation ? (
                <StyledActionButtons>
                  <AssignCandidatoButton
                    label={
                      <FormattedMessage
                        id="CandidateHeader.Asignar"
                        defaultMessage="Asignar"
                      />
                    }
                    candidatos={[candidate]}
                    handleChange={onChange}
                    currentContract={currentContract}
                  />
                </StyledActionButtons>
              ) : (
                <AssignCandidatoButton
                  label={
                    <FormattedMessage
                      id="CandidateHeader.Asignar a oferta"
                      defaultMessage="Asignar a oferta"
                    />
                  }
                  candidatos={[candidate]}
                  handleChange={onChange}
                />
              )}
            </StyledSection>
          </StyledSecondRow>
        </StyledDataWrapper>
      </StyledInfoRow>
    </StyledHeader>
  );
};

export default CandidateHeader;
